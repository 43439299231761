.timeline-more-button {
	background-color: var(--blue-background);
	align-self: center;
	padding: 0.5em 1em;
	border-radius: 20px;
	color: var(--blue);
	font-size: 0.85em;
	font-family: inherit;
	border: 1px solid var(--blue);
	cursor: pointer;
}
