/* Responsive */

.oscars-election-container {
    width: 100% !important;
    min-height: calc(100vh - 68px);
    max-height: calc(100vh - 68px);

    background-color: var(--oscars-black);

    font-family: "Familjen Grotesk";
    color: var(--oscars-white);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;



    h2 {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    h3 {
        color: var(--oscars-gold);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    h3,
    h4,
    h5 {
        margin-block-start: 0;
        margin-block-end: 0;
        transition: all 500ms;
    }

    h4 {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    h5 {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.oscars-election-content::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.oscars-election-content {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}


.oscars-election-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 72px;
    overflow-y: scroll;
    max-height: calc(100vh - 52px - 197px);
}

.oscars-category {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.oscars-nominee {
    display: flex;
    min-height: 58px;
    height: 58px;

    gap: 10px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.75);
    background: var(--oscars-gray);
    box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.25);
    transition: all 500ms;
    background-size: cover;
    background-image: none;
    border: 1px solid var(--oscars-gray);
}

.oscars-nominee:hover,
.oscars-nominee.selected {
    border-color: var(--oscars-gold);
}

.nominee-opacity {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    background: var(--oscars-radial-gradient)
}

.nominee-data {
    padding: 10px 15px;
    width: calc(100% - 30px);
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.oscars-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    background-color: var(--oscars-black);
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.oscars-footer i {
    display: block;
    padding: 15px;
}

.buttons-row {
    width: calc(100% - 30px);
    display: flex;
    justify-content: flex-end;
    padding: 15px;
}

.buttons-row button {
    background-color: transparent;
    color: var(--oscars-white);
    font-family: "Familjen Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.75);
    transition: all 500ms;
    width: 100%;
    max-height: 43px;
}

.buttons-row button:hover {
    border-color: var(--oscars-gold);
    color: var(--oscars-gold);
}

.buttons-row button.next {
    background-color: var(--oscars-white);
    color: var(--oscars-black);
}

.buttons-row button.next:hover {
    background-color: var(--oscars-gold);
    color: var(--oscars-white);
}

.buttons-row button:not(:last-of-type) {
    margin-right: 10px;
}

.name-input {
    margin-bottom: 182px;
}

.name-input p {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-block: 0;
    margin-bottom: 10px;
}

.name-input input {
    height: 50px;
    width: calc(100% - 20px);
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.75);
    background: rgba(255, 255, 255, 0.25);
    color: var(--oscars-white);
    font-size: 14px;
    padding-left: 20px;
}

.generate-buttons-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.final-step p {
    font-size: 20px;
}

.final-step i {
    font-size: 14px;
    margin-top: 16px;
    display: block;
}

.generate-button {
    border: 1px solid var(--oscars-white);
    border-radius: 8px;
    padding: 20px;
    width: calc(100% - 42px);
}

.generate-button p {
    font-size: 16px;
}

.generate-button:hover {
    background-color: var(--oscars-gold);
}

.generate-button:hover h3 {
    color: var(--oscars-black);
}


/* Desktop */
@media only screen and (min-width: 801px) {

    .oscars-election-container {
        width: 100% !important;
        min-height: calc(100vh - 68px);
        padding: 30px;
    }

    .oscars-election-content {
        margin-bottom: 87px;
        max-height: calc(100vh - 87px - 52px);
    }

    .buttons-row {
        padding: 15px 30px 30px 0;
    }

    .oscars-nominee {
        width: 600px;

    }

    .oscars-category {
        width: 570px;
    }

    .buttons-row button {
        width: auto;
    }

    .name-input input {
        height: 50px;
        width: auto;
        max-width: 600px;
        padding: 0px 510px 0px 20px;
        align-items: center;
        align-self: stretch;
    }

    .generate-buttons-row {
        flex-direction: row;
    }

    .generate-button {
        width: 50%;
    }

    .oscars-footer {
        flex-direction: row;
    }

    .oscars-footer i {
        padding: 15px 30px 30px 30px;
    }

    .name-input {
        margin-bottom: 105px;
    }

}