button.toggle {
	background: transparent;
	border: 0;
	color: var(--blue);
	font-weight: 700;
	padding: 0;
	font-size: 1em;
	transition: color ease 0.2s;
	font-family: inherit;
	cursor: pointer;
}

button.toggle:hover {
	color: var(--red);
}

button.toggle u {
	text-underline-offset: 2px;
}
