@import url(https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;600;700;800;900&display=swap%27);
:root {
	--blue: #1480ff;
	--blue-background: #1480ff1a;
	--red: #f06648;
	--red-background: #f066481a;
	--background: #eae5da;
	/* year top colors
	bg = pantone color of the year
	tx = enter in coolors and choose combining color putting the bg color in the middle column */
	--covers-brightness: 100%;
	--twenty-three-bg: #bb2649;
	--twenty-three-tx: #040926;
	--twenty-two-bg: #6868ab;
	--twenty-two-tx: #ff934f;
	--twenty-one-bg: #f5df4d;
	--twenty-one-tx: #939597;
	--twenty-bg: #0f4c81;
	--twenty-tx: #fbf2c0;
	--nineteen-bg: #fa7268;
	--nineteen-tx: #028090;
	--eighteen-bg: #645394;
	--eighteen-tx: #f8c630;
	--seventeen-bg: #88b049;
	--seventeen-tx: #413c58;
	/* oscars */
	--oscars-gold: #d7a320;
	--oscars-white: #FFFFFF;
	--oscars-black: #101215;
	--oscars-gray: #181E24;
	--oscars-radial-gradient: radial-gradient(#00000000, #181E248C, #181E24 80%);
}

[data-theme="dark"] {
	--blue: #3ea2ff;
	--blue-background: #3ea2ff1a;
	--red: #a8412a;
	--red-background: #a8412a1a;
	--background: #241e18;
	/* original colors dimmed 50% using this web https://pinetools.com/darken-color */
	--covers-brightness: 70%;
	--twenty-three-bg: #5d1324;
	--twenty-three-tx: #020413;
	--twenty-two-bg: #313158;
	--twenty-two-tx: #a74000;
	--twenty-one-bg: #988508;
	--twenty-one-tx: #494a4b;
	--twenty-bg: #072640;
	--twenty-tx: #d0b20d;
	--nineteen-bg: #ab1105;
	--nineteen-tx: #004048;
	--eighteen-bg: #32294a;
	--eighteen-tx: #8f6c04;
	--seventeen-bg: #445824;
	--seventeen-tx: #201d2c;
	/* oscars */
	--oscars-gold: #d7a320;
	--oscars-white: #FFFFFF;
	--oscars-black: #101215;
	--oscars-gray: #181E24;
	--oscars-radial-gradient: radial-gradient(#00000000, #181E248C, #181E24 80%);
}

body {
	color: var(--blue);
	background-color: var(--background);
	margin: 0;
	font-family: "Public Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transition: background-color ease 0.2s;
}

a {
	transition: color ease 0.2s;
}

a:link {
	color: var(--blue);
}

a:visited {
	color: var(--blue);
}

a:hover {
	color: var(--red);
}

a:active {
	color: var(--red);
}


.about-me-container {
	margin: 0;
	margin-bottom: 2em;
}

.about-me-container a {
	text-underline-offset: 2px;
}

.about-me-name {
	font-weight: 700;
}

.gregg-logo {
	margin-left: 0;
}

.talking-fox {
	margin-top: 2em;
	margin: 0em;
	display: flex;
	align-items: center;
	grid-gap: 0.5em;
	gap: 0.5em;
}

.bubble {
	position: relative;
	background-color: var(--red-background);
	color: var(--red);
	border: 1px solid #f06648;
	padding: 1em;
	border-radius: 10px;
	align-items: stretch;
	font-size: 1rem;
	transition: height 1s;
}
.bubble:after {
	content: "";
	position: absolute;
	display: block;
	width: 0;
	z-index: 1;
	border-style: solid;
	border-color: transparent #f06648;
	border-width: 8px 0 8px 8px;
	top: 50%;
	right: -8px;
	margin-top: -8px;
}

@media only screen and (min-width: 801px) {
	.about-me-container {
		margin-left: 0;
		font-size: 1.5rem;
		max-width: 450px;
	}

	.about-me-container a {
		text-underline-offset: 2px;
	}

	.about-me-name {
		font-weight: 700;
	}

	.gregg-logo {
		margin: 0;
	}
}

.event {
	background-color: var(--blue-background);
	border-radius: 10px;
	align-items: stretch;
	padding: 1em;
	border: 1px solid var(--blue);
	display: flex;
	flex-direction: column;
	grid-gap: 1.5em;
	gap: 1.5em;
}

.event-current {
	background-color: var(--red-background);
	color: var(--red);
	border-color: var(--red);
}

.event-header {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	grid-gap: 1em;
	gap: 1em;
}

.event-current-badge {
	background-color: var(--red);
	color: var(--background);
	border-radius: 20px;
	padding: 0.2em 0.5em;
	text-align: center;
	font-size: 0.85em;
}

.event-body {
	display: flex;
	flex-direction: column;
	grid-gap: 1em;
	gap: 1em;
}

.event-body p {
	margin: 0;
}

.event-body h3 {
	margin: 0;
}

.event-body > ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
	grid-gap: 0.5em;
	gap: 0.5em;
	flex-wrap: wrap;
}

.event-body > ul > li {
	font-size: 0.85em;
	border: 1px solid var(--blue);
	border-radius: 10px;
	padding: 0.2em 0.7em;
	background: var(--background);
	transition: background ease 0.2s;
}

.event-body-current > ul > li {
	border-color: var(--red);
}

.timeline-more-button {
	background-color: var(--blue-background);
	align-self: center;
	padding: 0.5em 1em;
	border-radius: 20px;
	color: var(--blue);
	font-size: 0.85em;
	font-family: inherit;
	border: 1px solid var(--blue);
	cursor: pointer;
}

.swirl-container {
	text-align: center;
}

@media only screen and (min-width: 801px) {
	.swirl-container {
		text-align: left;
	}
}

.navbar-container {
	display: flex;
	grid-gap: 1em;
	gap: 1em;
}

.not-home {
	align-items: center;
	padding-left: 2em;
	margin-top: 1em;
}

.bold-link {
	font-weight: 700;
	padding: 0;
	font-size: 1em;
	transition: color ease 0.2s;
	font-family: inherit;
	text-underline-offset: 2px;
}

button.toggle {
	background: transparent;
	border: 0;
	color: var(--blue);
	font-weight: 700;
	padding: 0;
	font-size: 1em;
	transition: color ease 0.2s;
	font-family: inherit;
	cursor: pointer;
}

button.toggle:hover {
	color: var(--red);
}

button.toggle u {
	text-underline-offset: 2px;
}

#root {
	width: 100%;
}

body::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	overflow-x: hidden;
}

/* Mobile */

#root > * {
	width: 100%;
	box-sizing: border-box;
}

header {
	justify-content: space-between;
	height: 100%;
	padding: 2em;
}

.timeline-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1em 2em;
	grid-gap: 2em;
	gap: 2em;
}

/* Desktop */

@media only screen and (min-width: 801px) {
	#root > * {
		width: 50%;
		box-sizing: border-box;
	}

	header {
		float: left;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		position: fixed;
		padding: 3em;
	}

	.timeline-section {
		float: right;
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 100vh;
		padding: 3em 5em;
		grid-gap: 2em;
		gap: 2em;
	}
}

/* Mobile */

.tops-containers {
	margin-top: 1em;
}
.year-title {
	font-size: 72px;
}

.year-scroll {
	overflow-y: hidden;
	display: flex;
	align-items: baseline;
	flex-direction: row;
	grid-gap: 2em;
	gap: 2em;
	padding-right: 2em;
}

.year-row {
	display: flex;
	flex-direction: column;
	padding: 2em;
	padding-right: 0;
	width: 95vw;
}

.top-1-cover {
	width: 200px;
	height: 200px;
	filter: brightness(var(--covers-brightness));
	transition: filter ease 0.2s;
}

.top-podium-cover {
	width: 175px;
	height: 175px;
	filter: brightness(var(--covers-brightness));
	transition: filter ease 0.2s;
}

.cover {
	width: 150px;
	height: 150px;
	filter: brightness(var(--covers-brightness));
	transition: filter ease 0.2s;
}

/* Desktop */
@media only screen and (min-width: 801px) {
	.tops-containers {
		margin-top: 1em;
	}

	.year-title {
		transform: rotate(-90deg);
		font-size: 156px;
		width: 20%;
		margin-left: -0.5em;
	}

	.year-scroll {
		overflow-y: hidden;
		display: flex;
		align-items: baseline;
		flex-direction: row;
		grid-gap: 2em;
		gap: 2em;
		padding-right: 2em;
	}

	.year-row {
		display: flex;
		align-items: center;
		flex-direction: row;
		padding: 2em;
		width: 99vw;
	}

	.top-1-cover {
		width: 400px;
		height: 400px;
		filter: brightness(var(--covers-brightness));
		transition: filter ease 0.2s;
	}

	.top-podium-cover {
		width: 300px;
		height: 300px;
		filter: brightness(var(--covers-brightness));
		transition: filter ease 0.2s;
	}

	.cover {
		width: 200px;
		height: 200px;
		filter: brightness(var(--covers-brightness));
		transition: filter ease 0.2s;
	}
}

.album-data-container {
	display: flex;
	align-items: center;
	grid-gap: 0.5em;
	gap: 0.5em;
}

.album-position {
	font-size: 3em;
	margin-block-start: 0;
	margin-block-end: 0;
}

.artist-name {
	margin-block-end: 0.5em;
	font-weight: 700;
}

.album-name {
	margin-block-start: 0.5em;
	font-style: italic;
}

.twenty-three {
	background-color: var(--twenty-three-bg);
	color: var(--twenty-three-tx);
	transition: background-color ease 0.2s, color ease 0.2s;
}

.twenty-two {
	background-color: var(--twenty-two-bg);
	color: var(--twenty-two-tx);
	transition: background-color ease 0.2s, color ease 0.2s;
}

.twenty-one {
	background-color: var(--twenty-one-bg);
	color: var(--twenty-one-tx);
	transition: background-color ease 0.2s, color ease 0.2s;
}

.twenty {
	background-color: var(--twenty-bg);
	color: var(--twenty-tx);
	transition: background-color ease 0.2s, color ease 0.2s;
}

.nineteen {
	background-color: var(--nineteen-bg);
	color: var(--nineteen-tx);
	transition: background-color ease 0.2s, color ease 0.2s;
}

.eighteen {
	background-color: var(--eighteen-bg);
	color: var(--eighteen-tx);
	transition: background-color ease 0.2s, color ease 0.2s;
}

.seventeen {
	background-color: var(--seventeen-bg);
	color: var(--seventeen-tx);
	transition: background-color ease 0.2s, color ease 0.2s;
}

/* Responsive */

.oscars-election-container {
    width: 100% !important;
    min-height: calc(100vh - 68px);
    max-height: calc(100vh - 68px);

    background-color: var(--oscars-black);

    font-family: "Familjen Grotesk";
    color: var(--oscars-white);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;



    h2 {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    h3 {
        color: var(--oscars-gold);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    h3,
    h4,
    h5 {
        margin-block-start: 0;
        margin-block-end: 0;
        transition: all 500ms;
    }

    h4 {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    h5 {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.oscars-election-content::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.oscars-election-content {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}


.oscars-election-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 72px;
    overflow-y: scroll;
    max-height: calc(100vh - 52px - 197px);
}

.oscars-category {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.oscars-nominee {
    display: flex;
    min-height: 58px;
    height: 58px;

    grid-gap: 10px;

    gap: 10px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.75);
    background: var(--oscars-gray);
    box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.25);
    transition: all 500ms;
    background-size: cover;
    background-image: none;
    border: 1px solid var(--oscars-gray);
}

.oscars-nominee:hover,
.oscars-nominee.selected {
    border-color: var(--oscars-gold);
}

.nominee-opacity {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    background: var(--oscars-radial-gradient)
}

.nominee-data {
    padding: 10px 15px;
    width: calc(100% - 30px);
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.oscars-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    background-color: var(--oscars-black);
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.oscars-footer i {
    display: block;
    padding: 15px;
}

.buttons-row {
    width: calc(100% - 30px);
    display: flex;
    justify-content: flex-end;
    padding: 15px;
}

.buttons-row button {
    background-color: transparent;
    color: var(--oscars-white);
    font-family: "Familjen Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.75);
    transition: all 500ms;
    width: 100%;
    max-height: 43px;
}

.buttons-row button:hover {
    border-color: var(--oscars-gold);
    color: var(--oscars-gold);
}

.buttons-row button.next {
    background-color: var(--oscars-white);
    color: var(--oscars-black);
}

.buttons-row button.next:hover {
    background-color: var(--oscars-gold);
    color: var(--oscars-white);
}

.buttons-row button:not(:last-of-type) {
    margin-right: 10px;
}

.name-input {
    margin-bottom: 182px;
}

.name-input p {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-block: 0;
    margin-bottom: 10px;
}

.name-input input {
    height: 50px;
    width: calc(100% - 20px);
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.75);
    background: rgba(255, 255, 255, 0.25);
    color: var(--oscars-white);
    font-size: 14px;
    padding-left: 20px;
}

.generate-buttons-row {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.final-step p {
    font-size: 20px;
}

.final-step i {
    font-size: 14px;
    margin-top: 16px;
    display: block;
}

.generate-button {
    border: 1px solid var(--oscars-white);
    border-radius: 8px;
    padding: 20px;
    width: calc(100% - 42px);
}

.generate-button p {
    font-size: 16px;
}

.generate-button:hover {
    background-color: var(--oscars-gold);
}

.generate-button:hover h3 {
    color: var(--oscars-black);
}


/* Desktop */
@media only screen and (min-width: 801px) {

    .oscars-election-container {
        width: 100% !important;
        min-height: calc(100vh - 68px);
        padding: 30px;
    }

    .oscars-election-content {
        margin-bottom: 87px;
        max-height: calc(100vh - 87px - 52px);
    }

    .buttons-row {
        padding: 15px 30px 30px 0;
    }

    .oscars-nominee {
        width: 600px;

    }

    .oscars-category {
        width: 570px;
    }

    .buttons-row button {
        width: auto;
    }

    .name-input input {
        height: 50px;
        width: auto;
        max-width: 600px;
        padding: 0px 510px 0px 20px;
        align-items: center;
        align-self: stretch;
    }

    .generate-buttons-row {
        flex-direction: row;
    }

    .generate-button {
        width: 50%;
    }

    .oscars-footer {
        flex-direction: row;
    }

    .oscars-footer i {
        padding: 15px 30px 30px 30px;
    }

    .name-input {
        margin-bottom: 105px;
    }

}
/* Ballots */

.hidden-elements {
    max-height: 0;
    overflow: hidden;
}

.oscars-ballot-horizontal {
    background-color: var(--oscars-black);
    font-family: 'Familjen Grotesk';
    width: 1920px !important;
    max-width: 1920px;
    height: 1080px;
    max-height: 1080px;
    overflow: hidden;

    color: white;
    padding: 30px;

    .oscars-category-container.big {
        width: 919px;
        max-width: 919px;
    }

    .oscars-category-container.small {
        width: 450px;
        max-width: 450px;

    }

    .oscars-category-container {
        height: 297px;
        max-height: 297px;
    }
}

.oscars-ballot-vertical {
    background-color: var(--oscars-black);
    font-family: 'Familjen Grotesk';
    width: 1080px !important;
    max-width: 1080px;
    height: 1920px;
    max-height: 1920px;
    overflow: hidden;

    color: white;
    padding: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .oscars-category-container.big {
        width: 1020px;
        max-width: 1020px;
    }

    .oscars-category-container.small {
        width: 499px;
        max-width: 499px;

    }

    .oscars-category-container {
        height: 224px;
        max-height: 224px;
        background-position: 0 -30px;
    }
}


.oscars-category-container.not-selected {
    opacity: 0.4;
}

h2 {
    margin-block-start: 0;
    font-size: 40px;
    margin-block-end: 16px;
}

.ballot-content {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px;
}



.oscars-category-container {
    border-radius: 18px;
    border: 1px solid var(--oscars-gray);
    background: var(--oscars-gray);
    box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.25);
    background-repeat: no-repeat;
    background-size: cover;
   

    h3,
    h4,
    h6 {
        margin-block-start: 0;
        margin-block-end: 0;
    }

    h6 {
        color: var(--oscars-gold);
        font-family: "Familjen Grotesk";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    h3 {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }

    h4 {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.image-opacity {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 14px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 1px solid var(--oscars-gray);
    background: var(--oscars-radial-gradient)
}

.film-data {
    padding: 20px;
}

.watermark {
    text-align: right;
    opacity: 0.75;
}

p.watermark {
    margin-block: 0;
    margin-right: 60px;
}

p.watermark:first-of-type {
    margin-top: 16px;
}
